import styled from "@emotion/styled"
import { NumbersComponent } from "ui"

const StyledDiv = styled.div`
  background-color: ${(props) => props.theme.colors.propBlue};
`

type Props = {
  data: any
  accentColor: string
}

function InvestorsNumber({ data, accentColor }: Props) {
  return (
    <StyledDiv>
      <NumbersComponent
        data={data}
        accentColor={accentColor}
        backgroundColor={"transparent"}
        textColor={"white"}
        titleColor="white"
      />
    </StyledDiv>
  )
}

export default InvestorsNumber
